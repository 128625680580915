import { useEffect, useState } from 'react';
import Add from './components/Add';
import Log from './components/Log';
import Find from './components/Find';
import { Trip, User } from './models';
import { getAllUsers } from './api/users-api';
import { getAllTrips } from './api/trips-api';

function App() {
  const [users, setUsers] = useState<User[]>([]);
  const [trips, setTrips] = useState<Trip[]>([]);
  const [appView, setAppView] = useState('Find');
  const [showSpinner, setshowSpinner] = useState<boolean>(false);

  useEffect(() => {
    setInputsToReadOnly();

    setshowSpinner(true);
    getAllUsers()
      .then((response) => {
        setUsers(response.data);
        setshowSpinner(false);
      })
      .catch((err) => console.error(err));

    updateTrips();
  }, []);

  /**
   * Prevents keyboard to show on all input components for mobile devices
   */
  function setInputsToReadOnly() {
    const searchBoxes = document.getElementsByClassName('searchBox');
    Array.from(searchBoxes).forEach((el) =>
      el.setAttribute('readonly', 'true')
    );

    const datePickers = document.getElementsByClassName(
      'react-datepicker__input-container'
    );
    Array.from(datePickers).forEach((el) =>
      el.children[0].setAttribute('readonly', 'true')
    );
  }

  function onOptionChange(e: any) {
    setAppView(e.target.value);
  }

  function updateTrips() {
    setshowSpinner(true);
    getAllTrips()
      .then((response) => {
        setTrips(response.data);
      })
      .finally(() => setshowSpinner(false));
  }

  return (
    <div className='container'>
      <h1 style={{ textAlign: 'center' }}>Carpool</h1>

      <div className='row' style={{ marginBottom: '20px', marginTop: '20px' }}>
        <div
          className='btn-group'
          role='group'
          aria-label='Basic radio toggle button group'
        >
          <input
            type='radio'
            className='btn-check'
            name='btnradio'
            id='btnradioFind'
            autoComplete='off'
            defaultChecked
            value='Find'
            onChange={onOptionChange}
          />
          <label className='btn btn-outline-primary' htmlFor='btnradioFind'>
            Find
          </label>

          <input
            type='radio'
            className='btn-check'
            name='btnradio'
            id='btnradioAdd'
            autoComplete='off'
            value='Add'
            onChange={onOptionChange}
          />
          <label className='btn btn-outline-primary' htmlFor='btnradioAdd'>
            Add
          </label>
        </div>
      </div>
      <div
        className='row'
        style={{ display: appView === 'Add' ? 'block' : 'none' }}
      >
        <div className='col'>
          <Add
            appView={appView}
            users={users.filter((user) => user.inactive !== true)}
            updateTrips={updateTrips}
            setShowSpinner={setshowSpinner}
          />
        </div>
      </div>
      <div
        className='row'
        style={{ display: appView === 'Find' ? 'block' : 'none' }}
      >
        <div className='col'>
          <Find
            appView={appView}
            users={users.filter((user) => user.inactive !== true)}
            setShowSpinner={setshowSpinner}
          />
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col'>
          <Log
            users={users}
            trips={trips}
            showSpinner={showSpinner}
            updateTrips={updateTrips}
            setShowSpinner={setshowSpinner}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
